// from https://github.com/vuetifyjs/vuetify/issues/2234
import Sortable from "sortablejs"
// let sortable;
const SortableTable = {
    bind(el, binding, vnode) {
      let handle = binding.value
      if (handle) {
        let sortableElement = el.getElementsByTagName("tbody")[0];
        const options = {
            handle,
            animation: 150,
            onUpdate: function (event) {
                vnode.child.$emit("drag-sorted", event);
            }
        };
        // sortable = Sortable.create(sortableElement, options)
        Sortable.create(sortableElement, options)
      }
    },
};

export default SortableTable
